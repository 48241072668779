import "../styles/Skills.css";
import SkillCard from "./SkillCard";

export default function Skills() {
  return (
    <div className="container">
      <section className="section">
        <div className="section-header-div">
          <h2 className="section-header">Skills</h2>
        </div>
        <div className="section-content-div">
          <SkillCard />
        </div>
      </section>
    </div>
  );
}
