import "../styles/Header.css";

export default function Header() {
  return (
    <header>
      <img
        src="/assets/logo-white.png"
        alt="Header Logo"
        className="header-logo"
      ></img>
    </header>
  );
}
