import "../styles/SkillCard.css";
import skills from "../skills.json";

export default function SkillCard() {
  return (
    <div className="skill-cards-main">
      {skills.map((skill, index) => (
        <div key={index} className="skill-card">
          <div className="skill-icon-div">
            <img
              src={skill.iconUrl}
              alt={skill.name}
              className="skill-icon"
            ></img>
          </div>
          <div className="skill-info-div vertical-div">
            <p className="skill-name">{skill.name}</p>
            <p className="skill-description">{skill.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
