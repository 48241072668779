import "../styles/Footer.css";

export default function Footer() {
  return (
    <footer>
      <img
        src="/assets/logo-white.png"
        alt="Footer Logo"
        className="footer-logo"
      ></img>
    </footer>
  );
}
