import "../styles/MainProfile.css";

export default function MainProfile() {
  return (
    <div className="container">
      <div className=" main-profile-div  vertical-div">
        <div className="circle-photo-div">
          <img
            src="assets/pp.jpeg"
            alt="profile-photo"
            className="profile-photo"
          ></img>
        </div>
        <div className="name-title-div vertical-div">
          <p className="name">Ömer AKÇINAR</p>
          <p className="title">Jr. Software Developer</p>
          <p className="tags">
            Web Development | Full Stack Development | DevOps
          </p>
        </div>
      </div>
    </div>
  );
}
