import "../styles/Socials.css";

export default function Socials() {
  return (
    <div class="social-media-icons">
      <a
        href="https://www.linkedin.com/in/omrakcinar/"
        target="_blank"
        rel="noopener noreferrer"
        class="icon linkedin"
      >
        <i class="fab fa-linkedin-in"></i>
        <span class="icon-text">LinkedIn</span>
      </a>
      <a
        href="https://github.com/akcinardev"
        target="_blank"
        rel="noopener noreferrer"
        class="icon github"
      >
        <i class="fab fa-github"></i>
        <span class="icon-text">GitHub</span>
      </a>
      <a
        href="https://www.instagram.com/omrakcinar/"
        target="_blank"
        rel="noopener noreferrer"
        class="icon instagram"
      >
        <i class="fab fa-instagram"></i>
        <span class="icon-text">Instagram</span>
      </a>
      <a
        href="mailto:akcinardev@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
        class="icon mail"
      >
        <i class="fas fa-envelope"></i>
        <span class="icon-text">Mail</span>
      </a>
    </div>
  );
}
