import "../styles/About.css";

export default function About() {
  return (
    <div className="container">
      <section className="section">
        <div className="section-header-div">
          <h2 className="section-header">About Me</h2>
        </div>
        <div className="section-content-div">
          <p className="section-content">
            I’m a Jr. Software Developer exploring{" "}
            <strong>C#, ASP.NET, JavaScript, and React</strong>. I’m currently
            working on simple projects to enhance my skills and gain hands-on
            experience with these technologies.
          </p>
          <br></br>
          <p className="section-content">
            With a background in system engineering and DevOps, I bring a solid
            foundation in technical problem-solving and system management to my
            development work. I’m enthusiastic about learning and continuously
            improving, and I welcome opportunities for collaboration or
            feedback.
          </p>
          <br></br>
          <p className="section-content">
            Feel free to reach out from my socials on the bottom-right corner.
          </p>
        </div>
      </section>
    </div>
  );
}
