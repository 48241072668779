import About from "./components/About";
import Header from "./components/Header";
import MainProfile from "./components/MainProfile";
import "./App.css";
import Skills from "./components/Skills";
// import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Socials from "./components/Socials";

export default function App() {
  return (
    <div className="main-container">
      <Header />
      <MainProfile />
      <About />
      <Skills />
      <Footer />
      <Socials />
    </div>
  );
}
